import classnames from 'classnames';
import Link from 'next/link';

import CtaButton from 'components/Buttons/CtaButton';
import ContentfulImage from 'components/contentfulImage/ContentfulImage';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import {
  HeroBannerModuleCtaType,
  HeroBannerModuleEntry,
  TextColor,
} from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import Logger from 'lib/utils/Logger';

import styles from './HeroBannerModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';
import { buildImageSrcSet } from '../utils';

type HeroBannerModuleProps = ContentfulModuleProps<HeroBannerModuleEntry>;

/**
 * The HeroBanner in migrating off of using the "firstCtaText" and the "firstCtaUrl" properties
 * and will start using the "firstLink" property instead. We are currently supporting both until all
 * HeroBanners have been migrated over.
 */
const HeroBannerModule = (props: HeroBannerModuleProps) => {
  try {
    const fields = props.entry.fields || {};
    const {
      backgroundImage,
      backgroundImageQuality,
      ctaType = HeroBannerModuleCtaType.Button,
      header,
      firstCtaText,
      firstCtaUrl,
      firstLink,
      mobileBackgroundImage,
      mobileBackgroundImageQuality,
      subHeader,
      textColor = TextColor.Light,
    } = fields;
    const textColorClassName =
      textColor === TextColor.Light ? styles.whiteText : styles.blackText;
    const isCtaButton = ctaType === HeroBannerModuleCtaType.Button;
    const ctaText = firstLink ? firstLink.fields.displayText : firstCtaText;

    const imageUrl = getUrlFromAsset(backgroundImage);
    const mobileImageUrl = mobileBackgroundImage
      ? getUrlFromAsset(mobileBackgroundImage)
      : imageUrl;
    const imageSrcSetProps = {
      imageQuality: backgroundImageQuality,
      imageUrl,
      mobileImageQuality: mobileBackgroundImageQuality,
      mobileImageUrl,
    };
    const imageSrcSet = buildImageSrcSet(imageSrcSetProps);

    const content = (
      <>
        <ContentfulImage
          alt={subHeader || header}
          className={styles.image}
          imageContainerClassName={styles.imageContainer}
          {...imageSrcSet}
        />
        <div className={styles.contentContainer}>
          <div className={styles.header}>{header}</div>
          <div className={styles.subHeader}>{subHeader}</div>
          <div className={styles.buttonsContainer}>
            {isCtaButton ? (
              <CtaButton className={styles.button}>{ctaText}</CtaButton>
            ) : (
              <div className={styles.linkCta}>{ctaText}</div>
            )}
          </div>
        </div>
      </>
    );

    const rootClassName = classnames(
      styles.root,
      props.className,
      textColorClassName
    );

    if (firstLink) {
      return (
        <LocalizableLink
          {...firstLink.fields}
          className={rootClassName}
          dataAttributes={props.dataAttributes}
        >
          {content}
        </LocalizableLink>
      );
    }

    if (firstCtaUrl) {
      // firstCtaUrl has been depracated and should be removed at some point.
      return (
        <Link href={firstCtaUrl} passHref>
          <a className={rootClassName} href="placeholder">
            {content}
          </a>
        </Link>
      );
    }

    return (
      <div className={rootClassName} {...props.dataAttributes}>
        {content}
      </div>
    );
  } catch (error) {
    Logger.warn(
      `Unable to render HeroBanner - props: ${JSON.stringify(props)}`,
      error
    );
    return null;
  }
};

export default HeroBannerModule;
