import classnames from 'classnames';
import { Entry } from 'contentful';
import { ReactElement, ReactNode, ReactType } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import UltimateBanner from 'components/Banner/UltimateBannerModule/UltimateBannerModule';
import BannerModule from 'components/contentfulModules/BannerModule/BannerModule';
import BrandsLogosModule from 'components/contentfulModules/BrandsLogosModule/BrandsLogosModule';
import ButtonRowModule from 'components/contentfulModules/ButtonRowModule/ButtonRowModule';
import DoubleImageBannerModule from 'components/contentfulModules/DoubleImageBannerModule/DoubleImageBannerModule';
import EditorialHeroBannerModule from 'components/contentfulModules/EditorialHeroBannerModule/EditorialHeroBannerModule';
import GridLayoutModule from 'components/contentfulModules/GridLayoutModule/GridLayoutModule';
import HeroBannerModule from 'components/contentfulModules/HeroBannerModule/HeroBannerModule';
import ImageCarouselModule from 'components/contentfulModules/ImageCarouselModule/ImageCarouselModule';
import ImageHolderModule from 'components/contentfulModules/ImageHolderModule/ImageHolderModule';
import HorizontalStaggerModule from 'components/contentfulModules/layout/HorizontalStaggerModule';
import SplitViewModule from 'components/contentfulModules/layout/SplitViewModule';
import MultiLinkBanner from 'components/contentfulModules/MultiLinkBannerModule/MultiLinkBannerModule';
import ProductCarouselModule from 'components/contentfulModules/ProductCarouselModule/ProductCarouselModule';
import RelatedLinksModule from 'components/contentfulModules/RelatedLinksModule/RelatedLinksModule';
import RichTextModule from 'components/contentfulModules/RichTextModule/RichTextModule';
import ShoppablePhotoModule from 'components/contentfulModules/ShoppablePhotoModule/ShoppablePhotoModule';
import ShortBannerModule from 'components/contentfulModules/ShortBannerModule/ShortBannerModule';
import SkinnyBannerModule from 'components/contentfulModules/SkinnyBannerModule/SkinnyBannerModule';
import SplitImageBannerModule from 'components/contentfulModules/SplitImageBannerModule/SplitImageBannerModule';
import StackingProductBlocksModule from 'components/contentfulModules/StackingProductBlocksModule/StackingProductBlocksModule';
import StackingTilesModule from 'components/contentfulModules/StackingTilesModule/StackingTilesModule';
import StaggeredSectionModule from 'components/contentfulModules/StaggeredSectionModule/StaggeredSectionModule';
import TallHeroBannerModule from 'components/contentfulModules/TallHeroBannerModule/TallHeroBannerModule';
import TastemakerCarouselModule from 'components/contentfulModules/TastemakerCarouselModule/TastemakerCarouselModule';
import TextBannerModule from 'components/contentfulModules/TextBannerModule/TextBannerModule';
import TileGridModule from 'components/contentfulModules/TileGridModule/TileGridModule';
import { getContentTypeId } from 'components/contentfulModules/utils';
import { VideoBannerModule } from 'components/contentfulModules/VideoBannerModule/VideoBannerModule';
import YoutubeEmbedModule from 'components/contentfulModules/YoutubeEmbedModule/YoutubeEmbedModule';

import { ContentfulContentTypeEnum, ContentfulModel } from 'lib/contentful';
import Logger from 'lib/utils/Logger';
import {
  buildGtmAttributesForContentModule,
  CONTENT_MODULE_TRACKING_CLASS,
} from 'lib/utils/tracking';

import styles from './component.module.scss';

type ComponentMap = {
  [contentTypeId: string]: ReactType;
};

// TODO [ch5676]: this should be a function
export const contentfulComponentsByContentType: ComponentMap = {
  [ContentfulContentTypeEnum.moduleBanner]: BannerModule,
  [ContentfulContentTypeEnum.moduleBrandsLogos]: BrandsLogosModule,
  [ContentfulContentTypeEnum.moduleButtonRow]: ButtonRowModule,
  [ContentfulContentTypeEnum.moduleDoubleImageBanner]: DoubleImageBannerModule,
  [ContentfulContentTypeEnum.moduleEditorialHeroBanner]:
    EditorialHeroBannerModule,
  [ContentfulContentTypeEnum.moduleGridLayout]: GridLayoutModule,
  [ContentfulContentTypeEnum.moduleHeroBanner]: HeroBannerModule,
  [ContentfulContentTypeEnum.moduleImageCarousel]: ImageCarouselModule,
  [ContentfulContentTypeEnum.moduleImageHolder]: ImageHolderModule,
  [ContentfulContentTypeEnum.moduleLayoutHorizontalStagger]:
    HorizontalStaggerModule,
  [ContentfulContentTypeEnum.moduleLayoutSplitView]: SplitViewModule,
  [ContentfulContentTypeEnum.moduleMultiLinkBanner]: MultiLinkBanner,
  [ContentfulContentTypeEnum.moduleProductCarousel]: ProductCarouselModule,
  [ContentfulContentTypeEnum.moduleRelatedLinks]: RelatedLinksModule,
  [ContentfulContentTypeEnum.moduleRichText]: RichTextModule,
  [ContentfulContentTypeEnum.moduleShoppablePhoto]: ShoppablePhotoModule,
  [ContentfulContentTypeEnum.moduleShortBanner]: ShortBannerModule,
  [ContentfulContentTypeEnum.moduleSkinnyBanner]: SkinnyBannerModule,
  [ContentfulContentTypeEnum.moduleSplitImageBanner]: SplitImageBannerModule,
  [ContentfulContentTypeEnum.moduleStackingProductBlocks]:
    StackingProductBlocksModule,
  [ContentfulContentTypeEnum.moduleStackingTiles]: StackingTilesModule,
  [ContentfulContentTypeEnum.moduleStaggeredSections]: StaggeredSectionModule,
  [ContentfulContentTypeEnum.moduleTallHeroBanner]: TallHeroBannerModule,
  [ContentfulContentTypeEnum.moduleTastemakerCarousel]:
    TastemakerCarouselModule,
  [ContentfulContentTypeEnum.moduleTextBanner]: TextBannerModule,
  [ContentfulContentTypeEnum.moduleUltimateBanner]: UltimateBanner,
  [ContentfulContentTypeEnum.moduleTileGrid]: TileGridModule,
  [ContentfulContentTypeEnum.moduleVideoBanner]: VideoBannerModule,
  [ContentfulContentTypeEnum.youTubeVideoEmbedded]: YoutubeEmbedModule,
};

const ErrorFallbackComponent = () => null;

const renderWithErrorBoundary = (component: ReactNode, key: string) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackComponent} key={key}>
      {component}
    </ErrorBoundary>
  );
};

type ComponentProps = {
  [propName: string]: Record<string, unknown> | number | string;
  moduleIndex: number | string;
};

export const renderContentfulEntry = (
  entry: Entry<ContentfulModel>,
  componentProps: ComponentProps
): ReactElement | null => {
  try {
    const contentTypeId = getContentTypeId(entry);

    if (!contentTypeId) {
      Logger.warn('Cannot determine contentful entry type');
      return null;
    }

    const ContentfulComponent =
      contentfulComponentsByContentType[contentTypeId];

    if (!ContentfulComponent) {
      Logger.warn(`Unknown component type: ${contentTypeId}`);
      return null;
    }

    const entryId = entry.sys.id;

    const { className, moduleIndex, ...rest } = componentProps;

    const componentClassName = classnames(
      className,
      CONTENT_MODULE_TRACKING_CLASS
    );

    const dataAttributes = buildGtmAttributesForContentModule(
      moduleIndex,
      entry
    );

    return renderWithErrorBoundary(
      <ContentfulComponentWrapper
        id={entry.sys.id}
        moduleDescription={entry.fields.moduleDescription}
      >
        <ContentfulComponent
          className={componentClassName}
          dataAttributes={dataAttributes}
          entry={entry}
          moduleIndex={moduleIndex}
          {...rest}
        />
      </ContentfulComponentWrapper>,
      entryId
    );
  } catch (error) {
    if (error instanceof Error) {
      Logger.warn('Error rendering contentful entry', error);
    }

    return null;
  }
};

// Wraps contentful module component with aria-describedby if provided
export type ContentfulComponentWrapperProps = {
  children: ReactElement;
  className?: string;
  id: string;
  moduleDescription?: string;
};
export const ContentfulComponentWrapper = ({
  children,
  className,
  id,
  moduleDescription,
}: ContentfulComponentWrapperProps): ReactElement => {
  if (!moduleDescription || !id) {
    return children;
  }

  const describedById = `contentful-module-${id}`;

  return (
    <div aria-describedby={describedById} className={className}>
      <span className={styles.moduleDescription} id={describedById}>
        {moduleDescription}
      </span>
      {children}
    </div>
  );
};
