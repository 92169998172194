import classnames from 'classnames';
import idx from 'idx';
import isEmpty from 'lodash/isEmpty';

import BrandLogo from './BrandLogo';
import { Column, Row } from 'components/Grid';

import { BrandsLogosModuleEntry } from 'lib/contentful';

import styles from './BrandsLogosModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

export type BrandsLogosModuleProps =
  ContentfulModuleProps<BrandsLogosModuleEntry>;

const BrandsLogosModule = ({
  className,
  dataAttributes,
  entry,
}: BrandsLogosModuleProps) => {
  if (isEmpty(entry)) {
    return null;
  }

  const items = idx(entry, _ => _.fields.items);

  if (!items || isEmpty(items)) {
    return null;
  }

  return (
    <Row className={classnames(styles.root, className)} {...dataAttributes}>
      {items.map((brand, index) => (
        <Column
          className={styles.brandLogoContainer}
          key={idx(brand, _ => _.fields.name) || index}
          md={2}
          sm={3}
          xs={6}
        >
          <BrandLogo model={brand} />
        </Column>
      ))}
    </Row>
  );
};

export default BrandsLogosModule;
