import { ReactElement } from 'react';

import ContentfulImage from 'components/contentfulImage/ContentfulImage';
import { buildImageSrcSet } from 'components/contentfulModules/utils';

import { EditorialHeroBannerModuleEntry } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';

import styles from './EditorialHeroBannerModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

type EditorialHeroBannerModuleProps =
  ContentfulModuleProps<EditorialHeroBannerModuleEntry>;

export const EditorialHeroBannerModule = (
  props: EditorialHeroBannerModuleProps
): ReactElement | null => {
  // input validation to avoid page crash
  if (!props?.entry?.fields) {
    return null;
  }

  const { className, dataAttributes, entry } = props;

  const { backgroundImage, header, mobileBackgroundImage, subHeader } =
    entry.fields;

  const imageUrl = backgroundImage?.fields?.file?.url;

  if (!imageUrl) {
    return null;
  }

  const webImageUrl = getUrlFromAsset(backgroundImage);
  const mobileImageUrl = mobileBackgroundImage
    ? getUrlFromAsset(mobileBackgroundImage)
    : webImageUrl;
  const imageSrcSet = buildImageSrcSet({
    imageUrl,
    mobileImageUrl,
  });

  return (
    <div className={className} {...dataAttributes}>
      <div className={styles.bannerImageContainer}>
        <ContentfulImage className={styles.bannerImage} {...imageSrcSet} />
      </div>
      <div>
        <div className={styles.headingText}>
          {header && header.length > 0 && (
            <h1 className={styles.header}>{header}</h1>
          )}
          {subHeader && subHeader.length > 0 && (
            <h2 className={styles.subHeader}>{subHeader}</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditorialHeroBannerModule;
