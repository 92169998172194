import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import times from 'lodash/times';
import { ReactElement } from 'react';

import ProductBlock from './components/ProductBlock/ProductBlock';
import ProductBlockSkeleton from './components/ProductBlockSkeleton/ProductBlockSkeleton';
import { Column, Container, Row } from 'components/Grid';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { StackingProductBlocksModuleEntry } from 'lib/contentful';
import Logger from 'lib/utils/Logger';

import {
  GET_PRODUCT_VARIANTS_BY_SKU,
  ProductVariantsBySkuResponse,
  ProductVariantsBySkuVariables,
} from './StackingProductBlocksModule.gql';

import styles from './StackingProductBlocksModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

const columnProps = {
  sm: 3,
  xs: 6,
};

type StackingProductBlocksModuleProps =
  ContentfulModuleProps<StackingProductBlocksModuleEntry>;

const StackingProductBlocksModule = (
  props: StackingProductBlocksModuleProps
): ReactElement | null => {
  const {
    data: productVariantsData,
    error,
    loading,
  } = useQuery<ProductVariantsBySkuResponse, ProductVariantsBySkuVariables>(
    GET_PRODUCT_VARIANTS_BY_SKU,
    {
      displayName: 'productVariantsData',
      variables: { skus: props?.entry?.fields?.skus },
    }
  );

  try {
    if (error) {
      throw error;
    }

    const productVariants = productVariantsData?.productVariant;

    const { className } = props;
    const { link } = props.entry.fields;

    const renderProductBlocks = () => {
      if (loading || !productVariants || !productVariants.length) {
        return times(4, index => (
          <Column {...columnProps} key={index}>
            <ProductBlockSkeleton />
          </Column>
        ));
      }

      return productVariants.map((productVariant, index) => {
        return (
          <Column {...columnProps} key={productVariant.sku}>
            <ProductBlock index={index} productVariant={productVariant} />
          </Column>
        );
      });
    };

    return (
      <div
        className={classnames(styles.root, className)}
        {...props.dataAttributes}
      >
        <Container>
          <Row>{renderProductBlocks()}</Row>

          {link && <LocalizableLink {...link.fields} className={styles.link} />}
        </Container>
      </div>
    );
  } catch (error) {
    Logger.error('StackingProductBlocksModule', error);
    return null;
  }
};

export default StackingProductBlocksModule;
