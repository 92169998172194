import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import { useCallback } from 'react';

import ProductCarouselCard from './ProductCarouselCard/ProductCarouselCard';
import cardStyles from './ProductCarouselCard/ProductCarouselCard.module.scss';
import Carousel from 'components/Carousel/Carousel';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import {
  CarouselHeaderLayout,
  ProductCarouselModuleEntry,
  ProductCarouselModuleImageType,
} from 'lib/contentful';

import {
  GET_PRODUCT_VARIANTS_BY_SKU,
  ProductVariantQueryResponse,
  ProductVariantQueryVariables,
} from './ProductCarouselModule.gql';

import styles from './ProductCarouselModule.module.scss';

import CarouselModuleHeader from '../CarouselModuleHeader/CarouselModuleHeader';
import { ContentfulModuleProps } from '../sharedTypes';

type ProductCarouselModuleProps =
  ContentfulModuleProps<ProductCarouselModuleEntry> & {
    headerClassName?: string;
  };

const EMPTY_TEXT = '';

const renderSkeletonLoader = (isHeaderEmbedded: boolean | undefined) => (
  <div
    className={classnames(cardStyles.root, {
      [styles.withEmbeddedHeader]: isHeaderEmbedded,
    })}
  >
    <div className={cardStyles.cardLoader} />
    <div className={cardStyles.descriptionContainer}>
      <div className={cardStyles.brand}>{EMPTY_TEXT}</div>
      <div className={cardStyles.title}>{EMPTY_TEXT}</div>
      <div className={cardStyles.price}>{EMPTY_TEXT}</div>
    </div>
  </div>
);

const ProductCarouselModule = (props: ProductCarouselModuleProps) => {
  const { headerData, imageType, link, skus } = props.entry.fields;
  const isHeaderEmbedded =
    headerData && headerData.fields.layout === CarouselHeaderLayout.embedded;

  const { data, loading: isLoading } = useQuery<
    ProductVariantQueryResponse,
    ProductVariantQueryVariables
  >(GET_PRODUCT_VARIANTS_BY_SKU, { variables: { skus } });

  const carouselHeader = useCallback(() => {
    return (
      <>
        {headerData && isHeaderEmbedded && (
          <div className={cardStyles.root}>
            <CarouselModuleHeader headerEntry={headerData} />
          </div>
        )}
      </>
    );
  }, [headerData, isHeaderEmbedded]);

  const carouselItems = useCallback(() => {
    const variants = data?.productVariant ?? [];

    return (
      <>
        {isLoading
          ? [0, 1, 2, 3, 4].map(() => renderSkeletonLoader(isHeaderEmbedded))
          : variants.map((variant, index) => (
              <ProductCarouselCard
                imageType={imageType || ProductCarouselModuleImageType.Model}
                isHeaderEmbedded={isHeaderEmbedded}
                key={variant.sku}
                position={index}
                product={variant.product}
                productVariant={variant}
              />
            ))}
      </>
    );
  }, [data?.productVariant, imageType, isHeaderEmbedded, isLoading]);

  return (
    <div
      className={classnames(styles.root, props.className)}
      {...props.dataAttributes}
    >
      {headerData && !isHeaderEmbedded && (
        <CarouselModuleHeader headerEntry={headerData} />
      )}
      <Carousel
        cardsContainerClassName={styles.cardsContainer}
        classNameForCenteringArrows={cardStyles.image}
      >
        {carouselHeader()}
        {carouselItems()}
      </Carousel>
      {link && !isHeaderEmbedded && (
        <LocalizableLink className={styles.link} {...link.fields}>
          {link.fields.displayText} &#8250;
        </LocalizableLink>
      )}
    </div>
  );
};

export default ProductCarouselModule;
