import classnames from 'classnames';
import flatten from 'lodash/flatten';

import Image from 'components/Image/Image';
import { MarkdownPromoContainer } from 'components/MarkdownPromoContainer/MarkdownPromoContainer';
import { MarkdownDisplayMode } from 'components/MarkdownText/MarkdownText';
import PriceInfo from 'components/PriceInfo/PriceInfo';
import { toCompactForm } from 'components/ProductGrid/ProductCard/ProductCardDetails/ProductCardDetails';
import {
  RecommendedPDPProduct,
  RecommendedProductVariant,
} from 'components/Recommendations/RecommendedProducts/RecommendedProducts';
import StarRating from 'components/Reviews/StarRating';

import { trackProductClicked } from 'lib/analytics';
import AnalyticsEvent from 'lib/analytics/events';
import { Breakpoints } from 'lib/breakpoints';
import { ProductCarouselModuleImageType } from 'lib/contentful';
import { comingSoonImageUrl } from 'lib/contentful/staticImageUrls';
import { useTrackEventOnScreenDuration } from 'lib/hooks/analytics/useTrackEventOnScreen';
import { MarkdownVariantDetails } from 'lib/hooks/promo/usePromoEligible';
import { DEFAULT_HEIGHT_ASPECT_RATIO } from 'lib/image';
import { ProductLink } from 'lib/links';
import { pageRoutes } from 'lib/routes';
import Logger from 'lib/utils/Logger';
import { buildGtmAttributesForProduct } from 'lib/utils/tracking';
import { getVariantImage } from 'lib/variant/assets';

import styles from './ProductCarouselCard.module.scss';

import { Product } from '../ProductCarouselModule.gql';

type ProductWithReviewSummary = Product &
  Partial<Pick<RecommendedPDPProduct, 'reviewSummary'>>;

export type ProductCarouselCardProps = {
  className?: string;
  classNameImage?: string;
  imageType: ProductCarouselModuleImageType;
  isHeaderEmbedded?: boolean;
  modelId?: string;
  modelVariantId?: string;
  onProductClicked?: () => void;
  position: number;
  product: Omit<ProductWithReviewSummary | RecommendedPDPProduct, 'variants'>;
  productVariant: RecommendedProductVariant;
  recommendationSectionTitle?: string;
};

const ProductCarouselCard = (
  props: ProductCarouselCardProps
): JSX.Element | null => {
  const {
    className,
    classNameImage,
    imageType,
    isHeaderEmbedded,
    modelId = '',
    modelVariantId = '',
    onProductClicked,
    position,
    product,
    productVariant,
    recommendationSectionTitle = '',
  } = props;
  const refTracker = useTrackEventOnScreenDuration(
    AnalyticsEvent.PRODUCT_LISTING_VISIBLE,
    {
      brand_slug: product.brandSlug,
      family_slug: product.familySlug,
      model_id: modelId,
      model_variant_id: modelVariantId,
      position,
      product_id: product.sid,
      product_slug: product.slug,
      variant_max_price: productVariant?.price,
      variant_min_price: productVariant?.price,
    }
  );
  if (!product || !productVariant?.price) {
    return null;
  }
  try {
    const { compareAtPrice, price } = productVariant;

    if (!product.slug || !product.brandSlug || !product.familySlug) {
      return null;
    }

    const imageUrl =
      getVariantImage(
        productVariant,
        imageType === ProductCarouselModuleImageType.Product
      )?.url || comingSoonImageUrl;

    const gtmAttributes = buildGtmAttributesForProduct({
      maxPrice: price,
      minPrice: price,
      modelId,
      modelVariantId,
      productId: product.sid,
      productPosition: position,
      productSlug: product.slug,
    });

    const altText = `${product.brand} ${product.title}`;

    const handleTrackingProductOnClick = () => {
      trackProductClicked({
        brand: product.brand || undefined,
        image_url: imageUrl,
        modelId,
        modelVariantId,
        name: product.title,
        position,
        price,
        product_id: product.sid,
        recommendationSectionTitle,
        sku: productVariant.sku,
        url: pageRoutes.productDetail.displayUrl({
          brandSlug: product.brandSlug,
          familySlug: product.familySlug,
          productSid: product.sid,
          productSlug: product.slug,
        }).pathname,
        variant_id: productVariant.sid,
      });

      if (typeof onProductClicked === 'function') {
        onProductClicked.call(productVariant);
      }
    };

    const markdownVariantDetails: MarkdownVariantDetails = {
      compareAtPrice: productVariant.compareAtPrice ?? 0,
      eligiblePromotions: productVariant.eligiblePromotions as string[],
      isDiscountable: productVariant.isDiscountable,
      isMarketplace: productVariant.isMarketplace ?? false,
      isProductBundle: productVariant.isProductBundle ?? false,
      offerDetails: productVariant.offerDetails,
      price: productVariant.price ?? 0,
      sku: productVariant.sku,
    };

    const productCategoryHierarchies = (product?.categories ?? []).map(item =>
      (item?.category ?? []).join(' > ')
    );

    const productCategories = flatten(
      (product?.categories || [])
        .map(item => {
          return item?.category || '';
        })
        .filter(Boolean)
    );

    const reviewSummary = product.reviewSummary;

    return (
      <ProductLink
        brandSlug={product.brandSlug}
        familySlug={product.familySlug}
        hierarchicalCategories={product.hierarchicalCategories}
        options={product.options}
        productId={product.sid}
        productSlug={product.slug}
        selectedVariant={productVariant}
      >
        <a
          className={classnames(
            styles.root,
            {
              [styles.withEmbeddedHeader]: isHeaderEmbedded,
            },
            className
          )}
          data-card-position={position}
          data-test-target="ProductCarouselCard"
          onClick={handleTrackingProductOnClick}
          onKeyDown={handleTrackingProductOnClick}
          {...gtmAttributes}
          ref={refTracker}
          role="none"
        >
          <Image
            alt={altText}
            className={classnames(styles.image, classNameImage)}
            imageContainerClassName={styles.imageContainer}
            mediumSrc={{
              categories: productCategories,
              height: Math.floor(
                (Breakpoints.widthExtraLarge / 5) * DEFAULT_HEIGHT_ASPECT_RATIO
              ),
              url: imageUrl,
              width: Breakpoints.widthExtraLarge / 5,
            }}
            smallSrc={{
              categories: productCategories,
              height: Math.floor(
                (Breakpoints.widthMedium / 4) * DEFAULT_HEIGHT_ASPECT_RATIO
              ),
              url: imageUrl,
              width: Breakpoints.widthMedium / 4,
            }}
            src={{
              categories: productCategories,
              height: Math.floor(
                (Breakpoints.widthSmall / 2) * DEFAULT_HEIGHT_ASPECT_RATIO
              ),
              url: imageUrl,
              width: Breakpoints.widthSmall / 2,
            }}
          />
          <div className={styles.descriptionContainer}>
            <div
              className={styles.brand}
              data-test-target="ProductCarouselCard__brand"
            >
              {product.brand}
            </div>
            <div className={styles.title}>{product.title}</div>
            <PriceInfo compareAtPrice={compareAtPrice} price={price} />
            {reviewSummary?.averageScore && (
              <StarRating
                numStars={reviewSummary.averageScore}
                optionalClassOverride={styles.productCardStarContainer}
              >
                {reviewSummary?.totalReview && (
                  <span className={styles.reviewCount}>
                    {toCompactForm(reviewSummary?.totalReview)}
                  </span>
                )}
              </StarRating>
            )}
            <div className={styles.promoContainer}>
              <MarkdownPromoContainer
                markdownDisplayMode={MarkdownDisplayMode.SUMMARY}
                productCategoryHierarchies={productCategoryHierarchies}
                productVariantDetails={markdownVariantDetails}
              />
            </div>
          </div>
        </a>
      </ProductLink>
    );
  } catch (error) {
    Logger.warn('Error rendering ProductCarouselCard', error);
    return null;
  }
};

export default ProductCarouselCard;
