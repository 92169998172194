import classnames from 'classnames';

import GridLayoutItem, {
  GridLayoutModuleItem,
} from './components/GridLayoutItem/GridLayoutItem';
import { Column, Container, Row } from 'components/Grid';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { GridLayoutModuleEntry } from 'lib/contentful';
import Logger from 'lib/utils/Logger';

import styles from './GridLayoutModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

type GridLayoutModuleProps = ContentfulModuleProps<GridLayoutModuleEntry>;

const GridLayoutModule = (props: GridLayoutModuleProps) => {
  try {
    const link = props.entry.fields.link;
    const { largeItem, smallItems } = generateItemsFromFields(props.entry);
    const [smallItemOne, smallItemTwo] = smallItems;

    return (
      <div
        className={classnames(styles.root, props.className)}
        {...props.dataAttributes}
      >
        <Container className={styles.gridContainer} fluid>
          <Row>
            <Column md={8} xs={12}>
              <GridLayoutItem itemProps={largeItem} />
            </Column>

            <Column md={4} xs={12}>
              <Row className={styles.smallItemRow}>
                <Column md={12} sm={6} xs={12}>
                  <GridLayoutItem isSmall itemProps={smallItemOne} />
                </Column>

                <Column md={12} sm={6} style={{ marginTop: 'auto' }} xs={12}>
                  <GridLayoutItem isSmall itemProps={smallItemTwo} />
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>

        {link && (
          <LocalizableLink {...link.fields} className={styles.link}>
            {link.fields.displayText} &#8250;
          </LocalizableLink>
        )}
      </div>
    );
  } catch (error) {
    Logger.error('GridLayoutModule', error);
    return null;
  }
};

export const generateItemsFromFields = (
  gridLayoutModel: GridLayoutModuleEntry
): {
  largeItem: GridLayoutModuleItem;
  smallItems: GridLayoutModuleItem[];
} => {
  const largeItem = {
    header: gridLayoutModel.fields.largeItemHeader,
    image: gridLayoutModel.fields.largeItemImage,
    link: gridLayoutModel.fields.largeItemLink,
    mobileImage: gridLayoutModel.fields.largeItemMobileImage,
    subHeader: gridLayoutModel.fields.largeItemSubHeader,
    textColor: gridLayoutModel.fields.largeItemTextColor,
  };

  return {
    largeItem,
    smallItems: [
      {
        header: gridLayoutModel.fields.smallItemOneHeader,
        image: gridLayoutModel.fields.smallItemOneImage,
        link: gridLayoutModel.fields.smallItemOneLink,
        mobileImage: gridLayoutModel.fields.smallItemOneMobileImage,
        subHeader: gridLayoutModel.fields.smallItemOneSubHeader,
        textColor: gridLayoutModel.fields.smallItemOneTextColor,
      },
      {
        header: gridLayoutModel.fields.smallItemTwoHeader,
        image: gridLayoutModel.fields.smallItemTwoImage,
        link: gridLayoutModel.fields.smallItemTwoLink,
        mobileImage: gridLayoutModel.fields.smallItemTwoMobileImage,
        subHeader: gridLayoutModel.fields.smallItemTwoSubHeader,
        textColor: gridLayoutModel.fields.smallItemTwoTextColor,
      },
    ],
  };
};

export default GridLayoutModule;
