import classnames from 'classnames';

import CtaButton from 'components/Buttons/CtaButton';
import Image from 'components/Image/Image';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { Breakpoints } from 'lib/breakpoints';
import { SplitImageBannerModuleEntry } from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import Logger from 'lib/utils/Logger';

import styles from './SplitImageBannerModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

const getImageSrcs = (imageUrl: string, mobileImage?: string) => {
  const src = {
    url: mobileImage || imageUrl,
    width: Breakpoints.widthSmall,
  };
  const smallSrc = { url: imageUrl, width: Breakpoints.widthMedium / 2 };
  const mediumSrc = { url: imageUrl, width: Breakpoints.widthExtraLarge / 2 };

  return { mediumSrc, smallSrc, src };
};

type SplitImageBannerModuleProps =
  ContentfulModuleProps<SplitImageBannerModuleEntry>;

const SplitImageBannerModule = (props: SplitImageBannerModuleProps) => {
  try {
    const { fields } = props.entry;
    const imageUrl = getUrlFromAsset(fields.image);
    const mobileImageUrl =
      fields.mobileImage && getUrlFromAsset(fields.mobileImage);
    const imageSrcs = getImageSrcs(imageUrl, mobileImageUrl);
    const title = fields.title;
    const subTitle = fields.subtitle;
    const buttonText = fields.buttonText;
    const link = fields.link;

    return (
      <div
        className={classnames(styles.sectionsContainer, props.className)}
        {...props.dataAttributes}
      >
        <section className={styles.imageSection} key={0}>
          <LocalizableLink {...link.fields} className={styles.clickableContent}>
            <Image alt={title} className={styles.image} {...imageSrcs} />
          </LocalizableLink>
        </section>
        <section className={styles.textSection} key={1}>
          <LocalizableLink {...link.fields} className={styles.clickableContent}>
            <div className={styles.content}>
              <h3 className={styles.title}>{title}</h3>
              {subTitle && <h4 className={styles.subTitle}>{subTitle}</h4>}
              <div {...link.fields} className={styles.buttonContainer}>
                <CtaButton className={styles.button}>{buttonText}</CtaButton>
              </div>
            </div>
          </LocalizableLink>
        </section>
      </div>
    );
  } catch (error) {
    Logger.warn(
      `Unable to render SplitImageBannerModule - props: ${JSON.stringify(
        props
      )}`,
      error
    );
    return null;
  }
};

export default SplitImageBannerModule;
