import classnames from 'classnames';
import { Asset } from 'contentful';
import { Fragment, ReactElement } from 'react';

import ContentfulImage from 'components/contentfulImage/ContentfulImage';
import {
  buildAllImageAssets,
  buildImageSrcSet,
} from 'components/contentfulModules/utils';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';

import { Breakpoints } from 'lib/breakpoints';
import {
  LocalizableLinkEntry,
  StaggeredSectionModuleEntry,
} from 'lib/contentful';
import { ContentfulComponentWrapper } from 'lib/contentful/component';
import { getUrlFromAsset } from 'lib/contentful/utils';

import styles from './StaggeredSectionModule.module.scss';

import { ContentfulModuleProps } from '../sharedTypes';

const mediumWidth = Breakpoints.widthExtraLarge / 2;
const smallWidth = Breakpoints.widthMedium / 2;
const xsWidth = Breakpoints.widthSmall;

type StaggeredSectionSection = {
  caption?: string;
  header: string;
  image: Asset;
  link: LocalizableLinkEntry;
  moduleDescription?: string;
  subHeader: string;
  wide: boolean;
};

const renderSectionHeader = (section: StaggeredSectionSection) => {
  const { caption, header, link, subHeader } = section;

  return (
    <Fragment>
      {caption && <div className={styles.caption}>{caption}</div>}
      {header && header.length > 0 && (
        <h2 className={styles.header}>{header}</h2>
      )}
      {subHeader && subHeader.length > 0 && (
        <h2 className={styles.subHeader}>{subHeader}</h2>
      )}
      <LocalizableLink {...link.fields} className={styles.link} />
    </Fragment>
  );
};

type StaggeredSectionModuleProps =
  ContentfulModuleProps<StaggeredSectionModuleEntry>;

const StaggeredSectionModule = (
  props: StaggeredSectionModuleProps
): ReactElement => {
  const { doNotStagger } = props.entry.fields;
  const sections = buildSections(props.entry);

  return (
    <div
      className={classnames(
        styles.root,
        props.className,
        !doNotStagger && styles.staggered
      )}
      {...props.dataAttributes}
    >
      {sections.map((section, index) => {
        const { header, image, link, moduleDescription, subHeader } = section;

        const imageUrl = getUrlFromAsset(image);
        const imageSrcSetProps = {
          imageUrl,
          imageWidth: mediumWidth,
          mobileImageUrl: imageUrl,
          mobileImageWidth: xsWidth,
          tabletImageWidth: smallWidth,
        };
        const imageSrcSet = buildImageSrcSet(imageSrcSetProps);

        return (
          <ContentfulComponentWrapper
            className={styles.section}
            id={`StaggeredSectionSection-${index}`}
            key={index}
            moduleDescription={moduleDescription}
          >
            <div className={!moduleDescription ? styles.section : undefined}>
              <div className={styles.descriptionContainerTop}>
                {renderSectionHeader(section)}
              </div>
              <LocalizableLink
                {...link.fields}
                className={styles.imageContainer}
              >
                <ContentfulImage
                  alt={subHeader || header}
                  assets={buildAllImageAssets(image)}
                  className={styles.image}
                  {...imageSrcSet}
                />
              </LocalizableLink>
              <div className={styles.descriptionContainerBottom}>
                {renderSectionHeader(section)}
              </div>
            </div>
          </ContentfulComponentWrapper>
        );
      })}
    </div>
  );
};

const buildSections = (
  entry: StaggeredSectionModuleEntry
): StaggeredSectionSection[] => {
  const section1: StaggeredSectionSection = {
    caption: entry.fields.section1Caption,
    header: entry.fields.section1Header,
    image: entry.fields.section1Image,
    link: entry.fields.section1Link,
    moduleDescription: entry.fields.section1ModuleDescription,
    subHeader: entry.fields.section1SubHeader,
    wide: entry.fields.section1Wide,
  };

  const section2: StaggeredSectionSection = {
    caption: entry.fields.section2Caption,
    header: entry.fields.section2Header,
    image: entry.fields.section2Image,
    link: entry.fields.section2Link,
    moduleDescription: entry.fields.section2ModuleDescription,
    subHeader: entry.fields.section2SubHeader,
    wide: entry.fields.section2Wide,
  };

  return [section1, section2];
};

export default StaggeredSectionModule;
