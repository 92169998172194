import { Entry } from 'contentful';
import find from 'lodash/find';
import pick from 'lodash/pick';

import {
  ShoppablePhotoEntry,
  ShoppablePhotoProductItemModel,
} from 'lib/contentful';
import Logger from 'lib/utils/Logger';

import { ShoppablePhotoProductVariant } from 'components/contentfulModules/ShoppablePhotoModule/ShoppablePhotoModule.gql';

import { ShoppablePhoto, ShoppablePhotoProductItem } from 'types/app';

export const generateImageAltText = (contentfulEntry: ShoppablePhotoEntry) => {
  try {
    const itemSummaryTexts = contentfulEntry.fields.linkedProducts.map(
      linkedProduct => linkedProduct.fields.summaryText
    );
    return `Shoppable photo featuring ${itemSummaryTexts.join(', ')}`;
  } catch (e) {
    return 'Shoppable photo';
  }
};

export const generateShoppablePhoto = (
  contentfulEntry: ShoppablePhotoEntry,
  variantData?: ShoppablePhotoProductVariant[]
): ShoppablePhoto => {
  const { linkedProducts: linkedProductsEntry = [], photo } =
    contentfulEntry.fields;
  const imageUrl = photo.fields.file.url;
  const { height: imageHeight, width: imageWidth } =
    photo.fields.file.details.image;

  const linkedProducts: ShoppablePhotoProductItem[] = [];
  linkedProductsEntry.forEach(
    (linkedProductEntry: Entry<ShoppablePhotoProductItemModel>) => {
      // enrich with sku, summaryText from contentful entry
      const contentfulProps = {
        ...linkedProductEntry.fields,
      };

      // find this product in the API response
      const apiProductVariant =
        variantData &&
        find(
          variantData,
          shoppableProduct => shoppableProduct.sku === contentfulProps.sku
        );
      if (!apiProductVariant) {
        Logger.warn(
          `ShoppablePhotoModule: Unable to match sku '${contentfulProps.sku}' to API response.`
        );
        return undefined;
      }

      const productProps = pick(apiProductVariant.product, [
        'brandSlug',
        'familySlug',
        'sid',
        'slug',
      ]);

      linkedProducts.push({
        ...contentfulProps,
        ...productProps,
      } as ShoppablePhotoProductItem);
    }
  );

  const shoppablePhoto = {
    imageHeight,
    imageUrl,
    imageWidth,
    linkedProducts,
  } as ShoppablePhoto;

  return shoppablePhoto;
};
