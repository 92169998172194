import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';

import UltimateButton from './UltimateButton/UltimateButton';
import UltimateText, { UltimateTextProps } from './UltimateText/UltimateText';
import TextButton from 'components/Buttons/TextButton/TextButton';
import ContentfulImage from 'components/contentfulImage/ContentfulImage';
import { ContentfulModuleProps } from 'components/contentfulModules/sharedTypes';
import { buildImageSrcSet } from 'components/contentfulModules/utils';
import LocalizableLink from 'components/LocalizableLink/LocalizableLink';
import Modal from 'components/Modals/Modal/Modal';

import {
  UltimateBannerHeight,
  UltimateBannerModuleEntry,
} from 'lib/contentful';
import { getUrlFromAsset } from 'lib/contentful/utils';
import Logger from 'lib/utils/Logger';

import styles from './UltimateBannerModule.module.scss';

export type UltimateBannerModuleProps =
  ContentfulModuleProps<UltimateBannerModuleEntry>;

const UltimateBannerModule = (props: UltimateBannerModuleProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnModalCloseClick = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  try {
    const {
      bannerHeight = UltimateBannerHeight.regular,
      bannerLink,
      buttons = [],
      textLineOne,
      textLineThree,
      textLineTwo,
      image,
      imageAltText,
      mobileImage,
      modalButtonText,
      modalContent,
      oneButtonPerRow = true,
    } = props.entry.fields;

    const imageUrl = getUrlFromAsset(image);
    const mobileImageUrl = mobileImage
      ? getUrlFromAsset(mobileImage)
      : imageUrl;
    const imageSrcSet = buildImageSrcSet({
      imageUrl,
      mobileImageUrl,
    });

    const modalContentInner = modalContent?.fields?.content;
    const modalHeader = modalContent?.fields?.header;
    const bannerLinkFields = bannerLink?.fields;

    const renderTextLine = (
      textLinePieces?: UltimateTextProps[],
      ignoreTallPadding = false
    ) => {
      if (!Array.isArray(textLinePieces) || textLinePieces.length < 1) {
        return null;
      }

      return (
        <div
          className={classnames({
            [styles.tallSpacingTextLine]:
              bannerHeight === UltimateBannerHeight.tall && !ignoreTallPadding,
          })}
        >
          {textLinePieces.map((textLinePiece, index) => (
            <UltimateText
              {...textLinePiece}
              key={`${textLinePiece.sys.id}`}
              prefix={index > 0 ? ' ' : ''}
            />
          ))}
        </div>
      );
    };

    const renderButtons = () => {
      if (!Array.isArray(buttons) || buttons.length < 1) {
        return null;
      }

      return (
        <div
          className={classnames(styles.buttonGrid, {
            [styles.twoButtonsPerRow]: !oneButtonPerRow,
            [styles.tallSpacingButtons]:
              bannerHeight === UltimateBannerHeight.tall,
          })}
        >
          {buttons?.map(button => {
            return (
              <div
                className={classnames(styles.gridItem, {
                  [styles.twoColumnButton]: !oneButtonPerRow,
                })}
                key={button.sys.id}
              >
                <UltimateButton {...button} />
              </div>
            );
          })}
        </div>
      );
    };

    const renderModalContent = () => {
      if (!modalContentInner) {
        return null;
      }

      return (
        <>
          <TextButton className={styles.modalButton} onClick={handleOpenModal}>
            {modalButtonText ?? 'See Details'}
          </TextButton>
          <Modal
            header={modalHeader}
            isOpen={isModalOpen}
            onCloseClick={handleOnModalCloseClick}
          >
            {documentToReactComponents(modalContentInner)}
          </Modal>
        </>
      );
    };

    const renderBackgroundImage = () => {
      const backgroundImage = (
        <ContentfulImage
          alt={imageAltText}
          className={styles.image}
          imageContainerClassName={styles.imageContainer}
          {...imageSrcSet}
        />
      );

      // Do not add link if there are either buttons or modal content
      if (!bannerLinkFields || !isEmpty(buttons) || !!modalContentInner) {
        return backgroundImage;
      }

      return (
        <LocalizableLink
          className={styles.imageContainer}
          {...bannerLinkFields}
        >
          {backgroundImage}
        </LocalizableLink>
      );
    };

    return (
      <div
        className={classnames(styles.root, props.className, {
          [styles.tallRoot]: bannerHeight === UltimateBannerHeight.tall,
        })}
      >
        <div className={styles.contentContainer}>
          {renderBackgroundImage()}
          <div
            className={classnames(styles.contentInner, {
              [styles.tallSpacingContent]:
                bannerHeight === UltimateBannerHeight.tall,
              [styles.clickThroughContent]:
                isEmpty(buttons) && !modalContentInner,
            })}
          >
            {renderTextLine(textLineOne, true)}
            {renderTextLine(textLineTwo, true)}
            {renderTextLine(textLineThree)}
            {renderButtons()}
            {renderModalContent()}
          </div>
        </div>
      </div>
    );
  } catch (error) {
    Logger.error('Error rendering UltimateBannerModule', error);
    return null;
  }
};

export default UltimateBannerModule;
